<template>
  <div class="flex-container non-scrollable">
    <div class="scrollable">
      <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid p-5">
        <span class="page-sub-title">{{ $t('admin.params.adv.bankAccountTitle') }}</span>

        <!--      <div class="flex flex-column mb-5 mt-3">-->
        <!--          <div class="flex flex-row align-items-center mb-2">-->
        <!--            <label for="advBankAccountLabel" class="w-2 block text-900 font-medium"> {{ $t('admin.params.adv.bankAccountLabel') }}</label>-->
        <!--            <InputText id="advBankAccountLabel" v-model="advBankAccountLabel" type="text" class="w-3" />-->
        <!--          </div>-->
        <!--          <div class="flex flex-row align-items-center mb-2">-->
        <!--            <label for="advBankAccountIBAN" class="w-2 block text-900 font-medium"> {{ $t('admin.params.adv.bankAccountIban') }}</label>-->
        <!--            <InputText id="advBankAccountIBAN" v-model="advBankAccountIBAN" type="text" class="w-3" />-->
        <!--          </div>-->
        <!--          <div class="flex flex-row align-items-center mb-2">-->
        <!--            <label for="advBankAccountRIB" class="w-2 block text-900 font-medium"> {{ $t('admin.params.adv.bankAccountRib') }}</label>-->
        <!--            <InputText id="advBankAccountRIB" v-model="advBankAccountRIB" type="text" class="w-3" />-->
        <!--          </div>-->
        <!--          <div class="flex flex-row align-items-center mb-4">-->
        <!--            <label for="advBankAccountBIC" class="w-2 block text-900 font-medium"> {{ $t('admin.params.adv.bankAccountBIC') }}</label>-->
        <!--            <InputText id="advBankAccountBIC" v-model="advBankAccountBIC" type="text" class="w-3" />-->
        <!--          </div>-->

        <div class="card w-8 mt-3">
          <DataTable
              ref="dt" :value="advBankAccounts" v-model:selection="advSelectedBankAccounts" dataKey="customId"
              responsiveLayout="scroll"
          >
            <Column selectionMode="multiple" :exportable="false"></Column>
            <Column field="libelle" header="Libellé" :sortable="true">
              <template #body="slotProps">
                <InputText v-model="slotProps.data.libelle" placeholder="Label" />
              </template>
            </Column>
            <Column field="iban" header="IBAN" :sortable="true">
              <template #body="slotProps">
                <InputText v-model="slotProps.data.iban" placeholder="iban" />
              </template>
            </Column>
            <Column field="rib" header="RIB" :sortable="true">
              <template #body="slotProps">
                <InputText v-model="slotProps.data.rib" placeholder="rib" />
              </template>
            </Column>
            <Column field="bic" header="BIC" :sortable="true">
              <template #body="slotProps">
                <InputText v-model="slotProps.data.bic" placeholder="bic" />
              </template>
            </Column>

            <Column :exportable="false">
              <template #body="slotProps">
                <!--              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editProduct(slotProps.data)" />-->
                <Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="deleteBankAccount(slotProps.data)" />
              </template>
            </Column>
          </DataTable>

          <Toolbar class="mt-2 p-2">
            <template #start>
              <Button label="Ajouter un compte bancaire" icon="pi pi-plus" class="p-button-success mr-2" @click="addBankAccount" />
              <Button label="Supprimer" icon="pi pi-trash" class="p-button-danger" @click="deleteSelectedBankAccounts" :disabled="!advSelectedBankAccounts || !advSelectedBankAccounts.length" />
            </template>
          </Toolbar>
        </div>

        <!--      <span class="page-sub-title">{{ $t('admin.params.adv.factoringAccountTitle') }}</span>-->

        <!--      <div class="flex flex-column mb-5 mt-3">-->
        <!--        <div class="flex flex-row align-items-center mb-2">-->
        <!--          <label for="advFactoringAccountLabel" class="w-2 block text-900 font-medium"> {{ $t('admin.params.adv.factoringAccountLabel') }}</label>-->
        <!--          <InputText id="advFactoringAccountLabel" v-model="advFactoringAccountLabel" type="text" class="w-3" />-->
        <!--        </div>-->
        <!--        <div class="flex flex-row align-items-center mb-4">-->
        <!--          <label for="advFactoringAccountBIC" class="w-2 block text-900 font-medium"> {{ $t('admin.params.adv.factoringAccountBIC') }}</label>-->
        <!--          <InputText id="advFactoringAccountBIC" v-model="advFactoringAccountBIC" type="text" class="w-3" />-->
        <!--        </div>-->
        <!--        <Button :label="$t('admin.params.adv.addFactoringAccountLabel')" class="p-button-link w-3 underline" @click="addFactoringAccount" />-->
        <!--      </div>-->
        <!--      <div class="card w-8 mt-3">-->
        <!--        <DataTable-->
        <!--            ref="dt" :value="advFactoringAccounts" v-model:selection="advSelectedFactoringAccounts" dataKey="id"-->
        <!--            responsiveLayout="scroll"-->
        <!--        >-->
        <!--          <Column selectionMode="multiple"></Column>-->
        <!--          <Column field="libelle" header="Libellé" :sortable="true">-->
        <!--            <template #body="slotProps">-->
        <!--              <InputText v-model="slotProps.data.libelle" placeholder="Label" />-->
        <!--            </template>-->
        <!--          </Column>-->
        <!--          <Column field="iban" header="IBAN" :sortable="true">-->
        <!--            <template #body="slotProps">-->
        <!--              <InputText v-model="slotProps.data.iban" placeholder="iban" />-->
        <!--            </template>-->
        <!--          </Column>-->

        <!--          <Column class="factoring-last-delete-cell">-->
        <!--            <template #body="slotProps">-->
        <!--              &lt;!&ndash;              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editProduct(slotProps.data)" />&ndash;&gt;-->
        <!--              <Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="deleteFactoringAccount(slotProps.data)" />-->
        <!--            </template>-->
        <!--          </Column>-->
        <!--        </DataTable>-->

        <!--        <Toolbar class="mt-2 p-2">-->
        <!--          <template #start>-->
        <!--            <Button label="Ajouter un compte d'affacturage" icon="pi pi-plus" class="p-button-success mr-2" @click="addFactoringAccount" />-->
        <!--            <Button label="Supprimer" icon="pi pi-trash" class="p-button-danger" @click="deleteSelectedFactoringAccounts" :disabled="!advSelectedFactoringAccounts || !advSelectedFactoringAccounts.length" />-->
        <!--          </template>-->
        <!--        </Toolbar>-->
        <!--      </div>-->


        <span class="page-sub-title">{{ $t('admin.params.adv.notificationsTitle') }}</span>

        <div class="flex flex-column mb-5 mt-3">
          <div class="flex flex-row align-items-center mt-3 mb-2">
            <Checkbox id="advStartInvoicingNotificationDateByEmail" v-model="advStartInvoicingNotificationDateByEmail" class="mr-2" :binary="true" />
            <label for="advStartInvoicingNotificationDateByEmail" class="mr-4">{{ $t('admin.params.adv.openInvoicingNotificationDateByEmail') }}</label>

            <Checkbox id="advStartInvoicingNotificationDateOnEditor" v-model="advStartInvoicingNotificationDateOnEditor" class="mr-2" :binary="true" />
            <label for="advStartInvoicingNotificationDateOnEditor">{{ $t('admin.params.adv.openInvoicingNotificationDateByEditor') }}</label>
          </div>
          <!--        <div class="flex flex-row align-items-center mb-2">-->
          <!--          <label for="advStartInvoicingNotificationDate" class="w-3 block text-900 font-medium"> {{ $t('admin.params.adv.openInvoicingNotificationDate') }}</label>-->
          <!--          <Dropdown-->
          <!--            id="advStartInvoicingNotificationDate"-->
          <!--            v-model="advStartInvoicingNotificationDate"-->
          <!--            :disabled="!advStartInvoicingNotificationDateByEmail && !advStartInvoicingNotificationDateOnEditor"-->
          <!--            :options="advStartInvoicingNotificationDateOptions"-->
          <!--            optionLabel="name"-->
          <!--            optionValue="value"-->
          <!--            placeholder="Selectionner une régularité"-->
          <!--          />-->
          <!--&lt;!&ndash;          <InputText id="advStartInvoicingNotificationDate" v-model="advStartInvoicingNotificationDate" :disabled="!advStartInvoicingNotificationDateByEmail && !advStartInvoicingNotificationDateOnEditor" type="text" class="w-3" />&ndash;&gt;-->
          <!--        </div>-->
        </div>

        <span class="page-sub-title">{{ $t('admin.params.adv.currencyTitle') }}</span>

        <div class="flex flex-column mb-5 mt-3">
          <div class="flex flex-row align-items-center mt-3 mb-2">
            <Checkbox id="advForeignCurrencyOption" v-model="advForeignCurrencyOption" class="mr-2" :binary="true" />
            <label for="advForeignCurrencyOption" class="mr-4">{{ $t('admin.params.adv.foreignCurrency') }}</label>
          </div>
        </div>

        <span class="page-sub-title">{{ $t('admin.params.adv.invoiceNaming') }}</span>

        <div class="mb-4 mt-4 w-8">
          <div class="flex" style="gap: 1rem">
          <span class="p-float-label w-8">
            <Chips id="template" v-model="invoiceNamingTemplate"/>
            <label for="template">{{ $t('admin.params.adv.format') }}</label>
          </span>
            <span class="p-float-label">
            <InputNumber id="startIndex" v-model="invoiceNamingStartIndex" min="1" highlightOnFocus/>
            <label for="startIndex">{{ $t('admin.params.adv.startIndex') }}</label>
          </span>
          </div>
          <div class="flex mt-2" style="gap: 0.5rem">
            <Chip class="cursor-pointer p-ripple" @click="addInvoiceNamingItem('-')" v-ripple label="-"/>
            <Chip class="cursor-pointer p-ripple" @click="addInvoiceNamingItem('YYYY')" v-ripple :label="getTranslation('admin.params.adv.year') + ' (YYYY)'"/>
            <Chip class="cursor-pointer p-ripple" @click="addInvoiceNamingItem('MM')" v-ripple :label="getTranslation('admin.params.adv.month') + ' (MM)'"/>
            <Chip class="cursor-pointer p-ripple" @click="addInvoiceNamingItem('NAME')" v-ripple :label="getTranslation('admin.params.adv.independentLastName') + ' (NAME)'"/>
            <Chip class="cursor-pointer p-ripple" @click="addInvoiceNamingItem('CLI')" v-ripple :label="getTranslation('admin.params.adv.clientName') + ' (CLI)'"/>
            <Chip class="cursor-pointer p-ripple" @click="addInvoiceNamingItem('REF')" v-ripple :label="getTranslation('admin.params.adv.invoice_number') + ' (REF)'"/>
          </div>
        </div>

        <span class="page-sub-title">{{ $t('admin.params.adv.avoirNaming') }}</span>

        <div class="mb-4 mt-4 w-8">
          <div class="flex" style="gap: 1rem">
          <span class="p-float-label w-8">
            <Chips id="template" v-model="avoirNamingTemplate"/>
            <label for="template">{{ $t('admin.params.adv.format') }}</label>
          </span>
          </div>
          <div class="flex mt-2" style="gap: 0.5rem">
            <Chip class="cursor-pointer p-ripple" @click="addInvoiceNamingItem('-', true)" v-ripple label="-"/>
            <Chip class="cursor-pointer p-ripple" @click="addInvoiceNamingItem('YYYY', true)" v-ripple :label="getTranslation('admin.params.adv.year') + ' (YYYY)'"/>
            <Chip class="cursor-pointer p-ripple" @click="addInvoiceNamingItem('MM', true)" v-ripple :label="getTranslation('admin.params.adv.month') + ' (MM)'"/>
            <Chip class="cursor-pointer p-ripple" @click="addInvoiceNamingItem('NAME', true)" v-ripple :label="getTranslation('admin.params.adv.independentLastName') + ' (NAME)'"/>
            <Chip class="cursor-pointer p-ripple" @click="addInvoiceNamingItem('CLI', true)" v-ripple :label="getTranslation('admin.params.adv.clientName') + ' (CLI)'"/>
            <Chip class="cursor-pointer p-ripple" @click="addInvoiceNamingItem('REF', true)" v-ripple :label="getTranslation('admin.params.adv.invoice_number') + ' (REF)'"/>
          </div>
        </div>

        <span class="page-sub-title">{{ $t('admin.params.adv.invoiceEmailTitle') }}</span>

        <div class="mt-3 mb-4 w-8">
          <Editor v-model="advInvoiceEmailTemplate" editorStyle="height: 320px;"/>
        </div>

        <div class="flex flex-row align-items-center mb-2">
          <label for="advPortingCompanyReceptionEmail" class="w-3 block text-900 font-medium"> {{ $t('admin.params.adv.invoiceReceptionEmail') }}</label>
          <InputText id="advPortingCompanyReceptionEmail" v-model="advPortingCompanyReceptionEmail" type="text" class="w-3" />
        </div>

        <div class="flex flex-row align-items-center mb-2" v-for="(email, index) in advRecipientsEmails" :key="index">
          <span class="w-3 block text-900 font-medium"> {{ index === 0 ? $t('admin.params.adv.invoiceRecipientsEmail') : '' }}</span>
          <InputText v-model="advRecipientsEmails[index]" type="text" class="w-3" />
        </div>

        <div class="flex flex-row align-items-center mb-2">
          <span class="w-3 block"></span>
          <Button label="Ajouter un destinataire" icon="pi pi-plus" class="p-button-outlined w-3" @click="advRecipientsEmails.push('')"/>
        </div>

        <span class="page-sub-title">Termes de paiement</span>

        <div class="mt-3 mb-4 w-8">
          <Editor v-model="advInvoicePaymentRequirements" editorStyle="height: 320px;"/>
        </div>

        <div v-if="hasAccess($route.name, true)" class="float-button">
          <Button :label="$t('admin.params.company.registerButton')" type="submit" class="w-full p-3"></Button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import {mapState} from "vuex";
// import {helpers, minLength, required} from "@vuelidate/validators";
import Alert from "@/utils/Alert";
import {disablePComponentsOfCurrentPage, formatHtmlToPdf, generateId, hasAccess, REGEX_VALIDATORS} from "@/utils/Misc";
import _ from "lodash";


export default {
  setup: () => ({v$: useVuelidate()}),
  name: "RH",
  data() {
    return {
      hasAccess: hasAccess,
      advBankAccounts: [
        // { id: 1, libelle: 'SWITZ', iban: 'FR3612739000302394161562M11', rib: 'rib', bic: 'SWITZ FR 01 AAA' },
        // { id: 2, libelle: 'FRANCE', iban: 'FR3612739000302394161562M12', rib: 'rib', bic: 'FRANC FR 02 AAA' }
      ],
      advSelectedBankAccounts: [],

      advStartInvoicingNotificationDate: '',
      advStartInvoicingNotificationDateByEmail: false,
      advStartInvoicingNotificationDateOnEditor: false,

      advStartInvoicingNotificationDateOptions: [
        { name: 'Tous les 20 jours', value: '20' },
        { name: 'Tous les 25 jours', value: '25' },
        { name: 'Tous les 30 jours', value: '30' }
      ],

      advForeignCurrencyOption: false,

      advInvoicePaymentRequirements: '',

      advInvoiceEmailTemplate: '',
      advPortingCompanyReceptionEmail: '',
      advRecipientsEmails: [''],

      submitted: false,

      invoiceNamingTemplate: [],
      avoirNamingTemplate: [],
      invoiceNamingStartIndex: 1
    }
  },
  async created() {
    // get only company settings ?
    await this.$store.dispatch('misc/getAllSettings', {})
    await this.$store.dispatch('bankaccount/getAllSettingsBankaccounts')
    await this.initializeFields()
  },
  mounted () {
    this.$nextTick(() => {
      if (!hasAccess(this.$route.name, true)) disablePComponentsOfCurrentPage(document)
    })
  },
  computed: {
    ...mapState({
      settings: state => state.misc.settings.ADV,
      bankAccounts: state => state.bankaccount.bankaccounts
    }),
  },
  validations() {
    return {
      // todo: later on describe the validation precisely, if so don't forget "*" after each require fields
    }
  },
  methods: {
    getTranslation (val) {
      return this.$t(val)
    },
    addInvoiceNamingItem (val, avoir = false) {
      if (avoir) this.avoirNamingTemplate.push(val)
      else this.invoiceNamingTemplate.push(val)
    },
    async initializeFields() {

      this.advBankAccounts = JSON.parse(JSON.stringify(this.bankAccounts)).map(account => ({ ...account, customId: generateId() }))

      this.advInvoiceEmailTemplate = this.settings.emailFacture.template
      this.advPortingCompanyReceptionEmail = this.settings.emailFacture.emailReception
      this.advRecipientsEmails = this.settings.emailFacture.emailRecipients

      if (!this.advRecipientsEmails.length) this.advRecipientsEmails.push('')

      this.advStartInvoicingNotificationDate = this.settings.notificationsPorte.date
      this.advStartInvoicingNotificationDateByEmail = this.settings.notificationsPorte.email
      this.advStartInvoicingNotificationDateOnEditor = this.settings.notificationsPorte.kaizen
      this.advForeignCurrencyOption = this.settings.devise.etrangere

      this.advInvoicePaymentRequirements = this.settings.invoicePaymentRequirements

      this.invoiceNamingTemplate = this.settings.invoiceNaming.template ?? []
      this.avoirNamingTemplate = this.settings.invoiceNaming.avoir ?? []
      this.invoiceNamingStartIndex = this.settings.invoiceNaming.startIndex
    },
    async handleSubmit(isFormValid) {
      this.submitted = true

      let error = false
      this.advBankAccounts.some((bankAccount) => {
        if (!bankAccount.libelle || !bankAccount.iban || !bankAccount.rib || !bankAccount.bic) {
          Alert.errorMessage(this, 'adminAdvFormBankAccountUnset')
          error = true
          return true // prevent from having multiple alerts for the same symptoms
        }
      })
      if (error) return

      if ((this.advStartInvoicingNotificationDateByEmail || this.advStartInvoicingNotificationDateOnEditor) && !this.advStartInvoicingNotificationDate) {
        Alert.errorMessage(this, 'adminAdvFormDateNotificationUnset')
        return
      }

      if (!this.invoiceNamingTemplate.length || !this.invoiceNamingStartIndex || !this.avoirNamingTemplate.length) {
        Alert.errorMessage(this, 'adminAdvInvoiceNaming')
        return
      }

      if (!this.invoiceNamingTemplate.includes('REF') || !this.avoirNamingTemplate.includes('REF')) {
        Alert.errorMessage(this, 'adminAdvInvoiceNamingRefMissing')
        return
      }

      if (!isFormValid) {
        Alert.errorMessage(this, 'fieldRequired', {symbole: '(*)'})
        return
      }

      try {
        const payload = {
          ADV: {
            devise: {
              etrangere: this.advForeignCurrencyOption
            },
            emailFacture: {
              emailReception: this.advPortingCompanyReceptionEmail,
              template: formatHtmlToPdf(this.advInvoiceEmailTemplate),
              emailRecipients: this.advRecipientsEmails.map((email) => email.trim()).filter(email => email !== '' && REGEX_VALIDATORS.EMAIL.test(email))
            },
            notificationsPorte: {
              date: this.advStartInvoicingNotificationDate,
              email: this.advStartInvoicingNotificationDateByEmail,
              kaizen: this.advStartInvoicingNotificationDateOnEditor
            },
            invoiceNaming: {
              template: this.invoiceNamingTemplate,
              startIndex: this.invoiceNamingStartIndex,
              avoir: this.avoirNamingTemplate
            },
            invoicePaymentRequirements: formatHtmlToPdf(this.advInvoicePaymentRequirements)
          }
        }

        // todo: might remove the previous own template if there was one

        await this.$store.dispatch('misc/updateSettings', payload)

        for (const bankAccount of this.advBankAccounts) {
          delete bankAccount.customId
          const existingBankAccount = this.bankAccounts.find(account => account.id === bankAccount.id)
          if (existingBankAccount && !_.isEqual(existingBankAccount, bankAccount)) {
            await this.$store.dispatch('bankaccount/updateBankaccount', bankAccount)
          } else if (!existingBankAccount) {
            await this.$store.dispatch('bankaccount/createBankaccount', { ...bankAccount, type: 'settings' })
          }
        }

        for (const existingBankAccount of this.bankAccounts) {
          if (!this.advBankAccounts.find(account => account.id === existingBankAccount.id)) {
            await this.$store.dispatch('bankaccount/deleteBankaccount', existingBankAccount)
          }
        }

        await this.$store.dispatch('bankaccount/getAllSettingsBankaccounts')
        this.advBankAccounts = JSON.parse(JSON.stringify(this.bankAccounts)).map(account => ({ ...account, customId: generateId() }))

        this.$toast.add({severity: 'success', summary: 'Succès', detail: 'Paramètres sauvegardés', life: 3000});
      } catch (error) {
        this.$toast.add({severity: 'error', summary: 'Erreur', detail: 'Une erreur s\'est produite', life: 3000});
      }
    },
    addBankAccount () {
      this.advBankAccounts.push({ libelle: '', iban: '', rib: '', bic: '', type: 'settings', customId: generateId() })
    },
    deleteBankAccount (bankAccount) {
      this.advBankAccounts = this.advBankAccounts.filter(account => account !== bankAccount)
    },
    deleteSelectedBankAccounts () {
      this.advBankAccounts = this.advBankAccounts.filter(account => !this.advSelectedBankAccounts.includes(account))
    },
  }
}
</script>

<style scoped>
</style>

<style>
</style>
